<template>
  <div class="mb-3">
    <label
      v-if="showLabel"
      class="form-label"
      :for="id"
    >
      {{ label || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
      <slot name="icons-after" />
    </label>
    <input
      :id="id"
      v-model="value"
      :readonly="readonly"
      :disabled="disabled"
      :indeterminate="indeterminate"
      :value="true"
      class="m-2"
      type="checkbox"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';

export default {
  props: [ 'modelValue', 'label', 'description', 'readonly', 'disabled', 'indeterminate' ],
  emits: ['update:modelValue'],
  data() {
    return {
      id: _.uniqueId('checkbox-input-')
    };
  },
  computed: {
    showLabel() {
      return this.label || this.description;
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  }
};
</script>
