<template>
  <div class="mb-3">
    <label
      v-if="label"
      class="form-label"
    >
      {{ label }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>

    <div class="checkbox-group">
      <slot name="before" />
      <div class="form-check">
        <input
          :id="`${prefix}_select_all`"
          type="checkbox"
          class="form-check-input"
          :checked="isAllSelected"
          :disabled="disabled"
          @change="handleSelectAllChange"
        />
        <label
          class="form-check-label"
          :for="`${prefix}_select_all`"
        >
          Select all
        </label>
      </div>
      <div
        v-for="(option, index) in options"
        :key="index"
        class="form-check"
      >
        <div class="d-flex align-items-center">
          <input
            :id="`${prefix}_${index}`"
            type="checkbox"
            class="form-check-input"
            :value="option.key"
            :checked="internalValue.includes(option.key)"
            :disabled="disabled"
            @change="handleCheckboxChange(option.key)"
          />
          <label
            class="form-check-label"
            :for="`${prefix}_${index}`"
          >
            {{ option.value }}
          </label>
          <div
            v-if="withInputs"
          >
            <input
              v-if="internalValue.includes(option.key)"
              :type="inputType"
              :class="inputClass"
              style="width: 80px; appearance: textfield;"
              :value="optionValues[option.key]"
              :min="inputMin"
              :max="inputMax"
              :step="inputStep"
              @input="handleInputChange(option.key, $event)"
            />
          </div>
          <label
            v-if="internalValue.includes(option.key)"
            class="form-check-label mx-1"
          >
            {{ inputLabel }}
          </label>
        </div>
      </div>
      <slot name="after" />
    </div>

    <div
      v-if="hasErrors"
      class="invalid-feedback d-block"
    >
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';

export default {
  props: [
    'modelValue',
    'label',
    'description',
    'errors',
    'options',
    'disabled',
    'withInputs',
    'inputType',
    'inputClass',
    'inputMin',
    'inputMax',
    'inputStep',
    'inputLabel'
  ],
  emits: [ 'update:modelValue', 'update:optionValue' ],
  data() {
    return {
      prefix: _.uniqueId('checkbox_'),
      internalValue: this.modelValue || [],
      optionValues: {},
    };
  },
  computed: {
    hasErrors() {
      return this.errors && this.errors.length > 0;
    },
    isAllSelected() {
      return this.internalValue.length === this.options.length;
    }
  },

  watch: {
    modelValue(newValue) {
      this.internalValue = newValue || [];
      this.divideEquallyAndFillInputs();
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
  },
  methods: {
    divideEquallyAndFillInputs() {
      if (this.inputType === 'number') {
        this.optionValues = {};
        if (this.internalValue.length > 0) {
          const prefillValue = Math.floor(100 / this.internalValue.length);
          this.internalValue.forEach((key) => {
            this.optionValues[key] = prefillValue;
          });
          const remainder = 100 - (prefillValue * this.internalValue.length);
          if (remainder > 0 && this.internalValue.length > 0) {
            this.optionValues[this.internalValue[0]] += remainder;
          }
        }
      }
      this.$emit('update:optionValue', this.optionValues);
    },
    handleSelectAllChange() {
      if (this.isAllSelected) {
        this.internalValue = [];
      } else {
        this.internalValue = this.options.map((option) => option.key);
      }
      this.divideEquallyAndFillInputs();
      this.$emit('update:modelValue', this.internalValue);
    },
    handleCheckboxChange(key) {
      let newValue = [...this.internalValue];
      if (newValue.includes(key)) {
        newValue = newValue.filter((item) => item !== key);
      } else {
        newValue.push(key);
      }
      this.internalValue = newValue;
      this.divideEquallyAndFillInputs();
      this.$emit('update:modelValue', this.internalValue);
    },

    handleInputChange(key, event) {
      const value = this.inputType === 'number' ? parseFloat(event.target.value) : event.target.value;
      if (this.inputType === 'number') {
        if (!isNaN(value) && value >= this.inputMin && value <= this.inputMax) {
          this.optionValues[key] = value;
        }
      } else {
        this.optionValues[key] = value;
      }
      this.$emit('update:optionValue', this.optionValues);
    }
  }
};
</script>

<style scoped>
.checkbox-group {
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 4px;
}

.form-check {
  margin-bottom: 5px;
}

.form-check-input {
  margin-right: 8px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin-left: 5px; /* Adds margin between text and spin buttons */
}
</style>
