export const passwordStrengthValidator = {
  $validator: (value) => {
    if (!value) {
      return true;
    }
    const regExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d\s]).{8,}$/g;
    return regExp.test(value);
  },
  $message: 'Must contain at least one letter, one number and one special character'
};
