<template>
  <div
    ref="reset-password-modal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Reset Password
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form>
            <TextInput
              v-model="password"
              :errors="v$.password.$errors"
              description="New password"
              label="New password"
              :disableAutocomplete="true"
              @blur="v$.password.$touch"
            />
          </form>
          <div class="modal-footer d-flex justify-content-between">
            <div>
              <button
                class="btn btn-warning"
                @click="generatePassword()"
              >
                Generate password
              </button>
            </div>
            <div>
              <button
                class="btn btn-success"
                @click="showResetPasswordConfirmationModalHandler"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DeleteConfirmationModal
    ref="user-management-reset-password-confirmation-modal"
    :onCancelHandler="cancelResetHandler"
    :onConfirmHandler="resetPasswordHandler"
  >
    <template #alert-message>
      <h6>
        <i class="bi bi-exclamation-triangle" />
        Do you really want to reset the password?
        This operation can not be undone!
      </h6>
    </template>
    <template #confirm-button>
      I agree and Reset
    </template>
  </DeleteConfirmationModal>
</template>

<script>

import TextInput from '@/components/common/TextInput.vue';
import { useVuelidate } from '@vuelidate/core';
import { minLength, required } from '@vuelidate/validators';
import { passwordStrengthValidator } from '@/lib/validators';
import { Modal } from 'bootstrap';
import DeleteConfirmationModal from '@/components/common/DeleteConfirmationModal.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    DeleteConfirmationModal,
    TextInput,
  },
  props: ['userId'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      resetPasswordModal: null,
      password: null,
      resetPasswordConfirmationModal: null,
    };
  },
  validations() {
    return {
      password: {
        required,
        minLength: minLength(8),
        passwordStrengthValidator
      }
    };
  },
  computed: {

  },
  async mounted() {
    this.resetPasswordModal = new Modal(this.$refs['reset-password-modal']);
    this.resetPasswordConfirmationModal = this.$refs['user-management-reset-password-confirmation-modal'].confirmationModal;
    this.resetPasswordConfirmationModal.hide();
    this.generatePassword();
  },
  methods: {
    ...mapActions({
      resetPassword: 'userManagement/resetPassword'
    }),
    initModal() {
      this.resetPasswordModal.show();
    },
    cancelResetHandler() {
      this.resetPasswordConfirmationModal.hide();
      this.resetPasswordModal.show();
    },
    async resetPasswordHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Password should be valid.');
        return false;
      }
      try {
        const data = {
          id: this.userId,
          password: this.password,
        };
        await this.resetPassword(data);
        this.$toast.success('Password was successfully reset');
      } catch (e) {
        this.$toast.error('Failed to reset password.');
      }
    },
    showResetPasswordConfirmationModalHandler() {
      this.resetPasswordModal.hide();
      this.resetPasswordConfirmationModal.show();
    },
    getRandomLetter() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      return letters.charAt(Math.floor(Math.random() * letters.length));
    },
    getRandomDigit() {
      const digits = '0123456789';
      return digits.charAt(Math.floor(Math.random() * digits.length));
    },
    getRandomSpecialChar() {
      const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';
      return specialChars.charAt(Math.floor(Math.random() * specialChars.length));
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [ array[i], array[j] ] = [ array[j], array[i] ];
      }
      return array;
    },
    generatePassword() {
      const length = 8;
      let password = [
        this.getRandomLetter(),
        this.getRandomDigit(),
        this.getRandomSpecialChar()
      ];

      for (let i = password.length; i < length; i++) {
        const randomChoice = Math.floor(Math.random() * 3);
        if (randomChoice === 0) {
          password.push(this.getRandomLetter());
        } else if (randomChoice === 1) {
          password.push(this.getRandomDigit());
        } else {
          password.push(this.getRandomSpecialChar());
        }
      }
      password = this.shuffleArray(password);

      this.password = password.join('');
    }
  }
};
</script>
