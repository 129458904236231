<template>
  <div
    ref="dropdownContainer"
    class="custom-dropdown mb-3"
  >
    <label
      v-if="label"
      class="form-label"
    >
      {{ label || '' }}
      <i
        v-if="description"
        ref="tooltip-toggler"
        :title="description"
        class="bi bi-info-circle"
        data-bs-toggle="tooltip"
      />
    </label>
    <div
      class="dropdown"
      :class="{ 'show': isOpen }"
    >
      <button
        class="btn btn-secondary dropdown-toggle select-btn"
        type="button"
        aria-expanded="isOpen"
        @click="toggleDropdown"
      >
        {{ selectedOption ? selectedOption.value : 'Select an option' }}
      </button>
      <ul
        class="dropdown-menu"
        :class="{ show: isOpen }"
      >
        <li
          v-for="option in inputOptions"
          :key="option.key"
          class="dropdown-item"
          @click="selectOption(option)"
        >
          {{ option.value }}
          <i
            v-if="option.description"
            class="bi bi-info-circle ms-2"
            :title="option.description"
            data-bs-toggle="tooltip"
          />
        </li>
      </ul>
    </div>
    <div class="invalid-feedback">
      <p
        v-for="error in errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </p>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { Tooltip } from 'bootstrap';

export default {
  props: [
    'modelValue',
    'label',
    'description',
    'errors',
    'options',
    'disabled',
    'multiple',
    'showAddAll',
    'showRemoveAll',
    'selectClass'
  ],
  emits: [ 'update:modelValue', 'blur' ],
  data() {
    return {
      prefix: _.uniqueId(),
      isOpen: false,
      selectedOption: null
    };
  },
  computed: {
    inputOptions() {
      let opts = [...this.options];

      if (this.multiple && this.showRemoveAll) {
        opts = [ this.removeAllOption, ...opts ];
      }

      if (this.multiple && this.showAddAll) {
        opts = [ this.addAllOption, ...opts ];
      }

      return opts;
    },
    addAllOption() {
      return { key: `${this.prefix}_ADD_ALL`, value: 'Select all' };
    },
    removeAllOption() {
      return { key: `${this.prefix}_REMOVE_ALL`, value: 'Remove all' };
    }
  },
  watch: {
    modelValue(newVal) {
      this.setSelectedOption(newVal);
    }
  },
  mounted() {
    if (this.description) {
      new Tooltip(this.$refs['tooltip-toggler']);
    }
    this.initializeOptionTooltips();
    this.setSelectedOption(this.modelValue);
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$nextTick(() => {
        if (this.isOpen) {
          this.initializeOptionTooltips();
        }
      });
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit('update:modelValue', option.key);
    },
    initializeOptionTooltips() {
      this.$nextTick(() => {
        const tooltips = Array.from(this.$el.querySelectorAll('.dropdown-item > i'));
        tooltips.forEach((icon) => {
          new Tooltip(icon);
        });
      });
    },
    setSelectedOption(value) {
      if (!value) {
        this.selectedOption = null;
        return;
      }
      this.selectedOption = this.inputOptions.find((option) => option.key === value) || null;
    },
    handleClickOutside(event) {
      const dropdownContainer = this.$refs.dropdownContainer;
      if (dropdownContainer && !dropdownContainer.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }
};
</script>


<style scoped>
.custom-dropdown {
  position: relative;
}

.custom-dropdown .dropdown-toggle {
  width: 100%;
  background-color: white; /* Set background to white */
  border: 1px solid #ccc; /* Border color */
  color: #000; /* Text color */
  display: flex; /* Use flexbox to align items */
  justify-content: space-between; /* Space between text and caret */
  align-items: center; /* Center items vertically */
  padding: .5rem 1rem; /* Adjust padding as needed */
}

.custom-dropdown .dropdown-menu {
  min-width: 100%;
  border: 1px solid #ddd;
  border-radius: .25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  padding: 0; /* Remove default padding */
  background-color: white; /* Ensure menu background is white for consistency */
}

.custom-dropdown .dropdown-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: .25rem 1rem;
  margin: 0;
  color: #000;
}

.custom-dropdown .dropdown-item:hover {
  background-color: #bcbaba; /* Slightly grey background on hover for better visibility */
}

.custom-dropdown .dropdown-item i {
  font-size: 1.25rem;
}

.select-btn {
  width: 100%;
  text-align: left;
}
</style>

